/* global jQuery */

jQuery(function($) {


	// COOKIE NOTICE

		function privacy_notice_close( cookies_decision ) {

			Cookies.set('privacy-cookies', cookies_decision, { expires: 365 });

			// slideUp() doesn't deal well with min-height
			$('#privacy-notice').css( {
				'height': $('#privacy-notice').outerHeight(),
				'min-height': 'auto'
			} ).slideUp(200);

		}

		if ( Cookies.get('privacy-cookies') === undefined && navigator.doNotTrack !== '1' && window.doNotTrack !== '1' ) {
			document.getElementById('privacy-notice').style.display = 'flex';
		}

		if ( navigator.doNotTrack === '1' || window.doNotTrack === '1' ) {
			Cookies.set('privacy-cookies', 'reject', { expires: 1 });
		}

		$('#privacy-cookies-accept').on( 'click', function() {
			privacy_notice_close( 'accept' );
		});

		$('#privacy-cookies-reject').on( 'click', function() {
			privacy_notice_close( 'reject' );
		});


  // MOBILE NAV TOGGLES

    $('#mobile-menu-toggle').on('click',function(){

      $('#mobile-menu-toggle i').toggleClass('icon-menu icon-cancel active');

      $('#header-menu').slideToggle(200);

    });

    $('#mobile-search-toggle').on('click',function(){

      $('#mobile-search-toggle i').toggleClass('icon-search icon-cancel active');

      $('#mobile-search-form').slideToggle(200,function(){

        $('#mobile-search-text').focus();

      });

    });


  // EXPLORE UCL

    $('#institution-toggle').on('click',function(){

      $('#institution').slideToggle(200);

      $('#institution-toggle').toggleClass('active').blur();

    });


  // MEGAMENU

    $('#header-menu > ul > li').hoverIntent(

      // When the hover happens
      function(){

        if (window.innerWidth > 767) {

          // Only do this if it's not already the active item
          if (!$(this).hasClass('active')) {

            // Set the submenu to fade in as a variable
            var menu_display = $(this).children('.sub-menu-wrapper');

            // If a new submenu exists, fade it and the overlay in
            if (menu_display.length > 0) {

              menu_display.fadeIn(200);

              $('#header-menu-overlay').fadeIn(200);

            }

            // Add the active class to this menu item
            $(this).addClass('active');

          }

        }

      },

      // We don't do anything on the end of hover, partially to maintain the submenu display
      // and partially because onmouseover / onmouseout was getting weird results
      function(){

        if (window.innerWidth > 767) {

          // Remove the active class
          $(this).removeClass('active');

          // Set the submenu to fade in as a variable
          var menu_display = $(this).children('.sub-menu-wrapper');

          // Fade out any existing submenus
          menu_display.fadeOut(200);

          // Fade out the overlay
          $('#header-menu-overlay').fadeOut(200);

        }

      }

    );


  // DESKTOP SEARCH TOGGLE

    function show_search_box() {

      $('#search-toggle').toggleClass('icon-search icon-cancel active');

      $('#header-menu li').animate( { opacity:0 } );

      var search_form_width = $('#header-nav-container').innerWidth() - 30 - $('#search-toggle').outerWidth();

      $('#search-form').animate(

        { width:search_form_width }, 200, 'swing',
        function(){

          $('#search-form').css({'width':'calc(100% - 46px)'});
          $('#search-text').focus();

        }

      );

      $('#search-toggle').data({ active:true });

    }

    function hide_search_box() {

      $('#search-toggle').toggleClass('icon-search icon-cancel active');

      $('#header-menu li').animate( { opacity:1 } );

      $('#search-text').blur();

      $('#search-form').animate({ width:0 }, 200, function(){ $('#search-toggle').blur() });

      $('#search-toggle').data({ active:false });

    }

    $('#search-toggle').on('click',function(e){

      if ( $('#search-toggle').data('active') !== true ) { show_search_box(); }
      else { hide_search_box(); }

    });

    $('#search-text').on('keyup', function(e){

      if (e.keyCode === 27) { hide_search_box(); }

    });


  // PUBLICATION FILTER DROPDOWN

    $('#publications-filter-team').on('change',function(){

      var publications_filter = $(this);

      publications_filter.addClass('is-active');

      if (publications_filter.val() !== '0') {

        $('#publications-filter-form').submit();

      } else {

        window.location = [location.protocol, '//', location.host].join('') + '/publications/';

      }

    });


  // SCROLL TO DEPARTMENT ON PEOPLE ARCHIVE

    $('body.post-type-archive-person .submenu a').on('click', function(e){

      e.preventDefault();

      var department = $(this).attr('href');

      $('html, body').animate(
        { scrollTop: $(department).offset().top },
        600,
        function(){ window.location.hash = department }
      );

    });


  // FUNCTION TO ALLOW THROTTLED FUNCTION EXECUTION ON WINDOW RESIZE

    var waitForFinalEvent = (function () {

      var timers = {};

      return function (callback, ms, uniqueId) {

        if (!uniqueId) { uniqueId = "Don't call this twice without a uniqueId"; }

        if (timers[uniqueId]) { clearTimeout (timers[uniqueId]); }

        timers[uniqueId] = setTimeout(callback, ms);

      };

    })();


  // SHARE POPUPS

    $('.share a').on('click',function(){

      // Track share in Google Analytics
      if (typeof ga !== 'undefined') {

        ga('send', 'event', 'Share', $(this).attr('class'), window.location.href);

      }

      // Popup a window if it's not an email link
      if (!$(this).hasClass('email')) {

        // Popup window settings
        var url = $(this).attr('href');
        var height = 285;
        if ($(this).hasClass('linkedin')) { height = 385; }

        // Pop the window
        var newwindow = window.open(url,'socialpop','height='+ height +',width=600');
        if (window.focus) { newwindow.focus(); }
        return false;

      }

    });


  // IMAGE ENHANCEMENT

    // Function to enhance images
    function images_enhance() {

      // Loop through all images with appropriate class
      $('.image-enhance').each(function() {

        // Set the jQuery object as a variable
        var $image_to_enhance = $(this);

        // Get the window width
        var window_width = $(window).innerWidth();

        // Only try this on sm screens and above
        if ( window_width > 767 ) {

          // Large
          if ( window_width > 1199 ) {

            var enhance_lg = $image_to_enhance.attr('data-enhance-lg');
            $image_to_enhance.css('background-image', enhance_lg);

          }

          // Medium
          else if ( window_width > 991 ) {

            var enhance_md = $image_to_enhance.attr('data-enhance-md');
            $image_to_enhance.css('background-image', enhance_md);

          }

          // Small
          else {

            var enhance_sm = $image_to_enhance.attr('data-enhance-sm');
            $image_to_enhance.css('background-image', enhance_sm);

          }

        }

      });

    }

    // Enhance images on window resize
    $(window).resize(function () {

        waitForFinalEvent(function(){

          images_enhance();

        }, 200, 'images-enhance');

    });

    // Enhance images on load
    images_enhance();


  // RESPONSIVE ASPECT RATIO

    // Find all blocks with responsive ratio class
    var allBlocks = $('.res-ratio');

    // Loop through each block
    allBlocks.each(function() {

      var block = $(this);

      block

        // Figure out the aspect ratio
        .data('aspectRatio', this.height / this.width)

        // Save it to a data attribute
        .data('blockParent', block.parent())

        // Remove the hard coded width/height
        .removeAttr('height')
        .removeAttr('width');

    });

    // Resize function
    function resizeNow() {

      // Resize all blocks according to their own aspect ratio
      allBlocks.each(function() {

        var block = $(this);

        var newWidth = block.data('blockParent').width();

        block
          .width(newWidth)
          .height(newWidth * block.data('aspectRatio'));

      });

    }

    // Trigger on window resize and kick off one resize to fix all blocks on page load
    $(window).resize(function () {

        waitForFinalEvent(function(){

          resizeNow();

        }, 200, 'responsive-ratio');

    });

    // Kick off one resize to fix all blocks on page load
    $(window).resize();



});
